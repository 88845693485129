<template>
  <div class="container">
    <form class="form flex-column equipments">
      <div class="card">
        <div class="card-body position-relative d-flex flex-column pt-5 px-0">
          <px-new-table
            class="align-middle"
            :is-hover-support="false"
            :header="header"
            :items="equipmentItems"
            :paginate="false"
          >
            <template slot="item" slot-scope="{ item }">
              <span v-if="!item.isEditMode" class="fw-bold fs-16px text-body">{{ item.attributes.item }}</span>
              <textarea-autosize
                v-else
                v-model="item.attributes.item"
                class="form-control form-control-solid fw-bold fs-16px text-body"
                rows="1"
                :min-height="42"
                :max-height="500"
              />
            </template>
            <template slot="description" slot-scope="{ item }">
              <span v-if="!item.isEditMode" class="fw-normal fs-16px text-body">
                {{ item.attributes.description }}
              </span>
              <textarea-autosize
                v-else
                v-model="item.attributes.description"
                class="form-control form-control-solid fw-normal min-w-300px fs-16px text-body"
                rows="1"
                :min-height="42"
                :max-height="500"
              />
            </template>
            <template slot="actions" slot-scope="{ item }">
              <px-dropdown
                :items="[
                  { title: 'Change Manually', action: () => toggleEditMode(item.id) },
                  {
                    title: 'Duplicate',
                    disabled: !item.attributes.item && !item.attributes.description,
                    action: () => duplicate(item.attributes),
                  },
                  { title: 'Delete', action: () => deleteEquipment(item.id) },
                ]"
              />
            </template>
          </px-new-table>
        </div>

        <div class="card-footer align-items-center border-0 p-0 text-end">
          <px-btn size="sm" color="light-primary" @click.native="addNew">
            <i class="bi bi-plus-lg" />
            New Item
          </px-btn>
        </div>
      </div>
    </form>

    <!--begin::Actions-->
    <div class="d-flex flex-stack mt-8 pt-8 border-top">
      <!--begin::Wrapper-->
      <div class="me-4">
        <px-btn color="secondary" :in-process="prevInProcess" :disabled="prevInProcess" @click.native="goToPrev">
          Back
        </px-btn>
      </div>
      <!--end::Wrapper-->
      <!--begin::Wrapper-->
      <div class="ms-auto">
        <px-btn
          color="success"
          extended-classes="me-2"
          :in-process="updateInProcess"
          :disabled="updateInProcess"
          @click.native="onUpdate"
        >
          Save
        </px-btn>
        <px-btn :in-process="nextInProcess" :disabled="nextInProcess" @click.native="goToNext">Next </px-btn>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Actions-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { nanoid } from 'nanoid';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

export default {
  mixins: [ProposalWizardMixin],
  data() {
    return {
      header: [
        {
          key: 'item',
          title: 'Item',
          sort: true,
          class: 'w-200px',
        },
        {
          key: 'description',
          title: 'Description',
          sort: true,
        },
        {
          key: 'actions',
          title: 'Action',
          sort: false,
          class: 'w-50px text-end pe-2',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      equipmentItems: 'proposalWizard/equipmentItems',
    }),
  },
  methods: {
    ...mapActions({
      megaUpdate: 'proposalWizard/megaUpdate',
      addNewEquipment: 'proposalWizard/addNewEquipment',
      actionDeleteEquipment: 'proposalWizard/deleteEquipment',
      toggleEquipmentsEditMode: 'proposalWizard/toggleEquipmentsEditMode',
    }),
    ...mapMutations({
      setEquipmentItems: 'proposalWizard/SET_EQUIPMENT_ITEMS',
    }),
    toggleEditMode(id) {
      this.toggleEquipmentsEditMode(id);
      this.setEquipmentItems(this.equipmentItems);
    },
    addNew() {
      this.addNewEquipment({
        id: nanoid(),
        type: 'equipment_item',
        attributes: {
          proposal_scope: this.itemId,
          equipment_item_template: '',
          item: '',
          description: '',
        },
        isEditMode: true,
      });
      this.setEquipmentItems(this.equipmentItems);
    },
    duplicate(attributes) {
      const newAttributes = { ...attributes };
      delete newAttributes.created_at;
      delete newAttributes.updated_at;

      this.addNewEquipment({
        id: nanoid(),
        type: 'equipment_item',
        attributes: newAttributes,
        isEditMode: false,
      });
      this.setEquipmentItems(this.equipmentItems);
    },
    deleteEquipment(id) {
      this.actionDeleteEquipment(id);
      this.setEquipmentItems(this.equipmentItems);
    },
  },
};
</script>
